.checked-container {
	font-size: 1.4rem;
	font-weight: 300;
	color: $ui-light-blue-2;
}
.checked-box {
	margin-right: 1rem;
}
.checked-box.active {
	img {
		content: url('../images/icons/checked-box.svg')
	}
}
.btn-primary {

	padding: 1.2rem 3rem;
	//min-width: 210px;
	text-align: center;

	outline: none;

	border-radius: 2px;

	background-color: $primary-button-bg;
	border: 3px solid $primary-button-bg;

	font-weight: 700;
	font-size: 1.3rem;
	text-transform: uppercase;
	color: $white;
	display: inline-block;
	letter-spacing: 0.8px;
	box-sizing: border-box;

	&:hover {
		background-color: $primary-button-bg-hover;
		border: 3px solid $primary-button-bg-hover;
		cursor: pointer;
		color: $white;
	}

	&:active,
	.selected {
		background-color: $primary-button-bg-active;
	}

	&:focus {
		border: 3px solid $primary-button-bg-hover;
	}

}
.button-fixed-width {
	//	min-width: 210px;
}

.btn-small {
	padding: 1rem 2rem;
}

.btn-tiny {
	padding: 0.5rem 1rem;
	font-size: 1rem;
}


.btn-secondary {

	@extend .btn-primary;

	background-color: transparent;
	color: $ui-light-blue-2;
	border: 3px solid  $ui-light-blue-2;

	&:hover {
		background-color: transparent;
		border: 3px solid  $ui-light-blue-3;
		color: $ui-light-blue-3;
	}

	&:focus {
		border: 3px solid  $ui-light-blue-3;
	}

	&:disabled {
		opacity:0.5;
		cursor: default;
	}

}

.btn-secondary--orange {
	@extend .btn-secondary;
	color: $primary-button-bg;

	border: 3px solid $primary-button-bg;

	&:hover {
		color: $white;
		background-color: $primary-button-bg;
		border: 3px solid $primary-button-bg;
	}

	&:focus {
		border: 3px solid $primary-button-bg-hover;
	}

}

.btn-secondary--warning {
	color: $alert-red;
	border: 3px solid $alert-red;
}


.btn-primary--warning {

	border: 3px solid $alert-red;
	color: $alert-red;

	&:hover {
		border: 3px solid $alert-red;
		background-color: $alert-red;
		color: $white;
		border-width: 3px;
	}

}



.btn-default {
	@extend .btn-primary;
	background-color: white;
	color: $primary-button-bg;
	border-width: 3px;
	font-weight: bolder;
}
.btn-empty-orange {
	@extend .btn-primary;
	background-color: white;
	color: $primary-button-bg;
	border-width: 3px;
	&:hover {
		border-width: 3px;
	}
	font-weight: bolder;
}

.btn-icon {
	cursor: pointer;
}
@media (max-width: 767px) {
	.btn-primary {

		padding: 0.8rem 2.5rem;

		font-weight: 700;
		font-size: 1.1rem;

	}
}
