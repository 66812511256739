// links
$link-grey: #c6c6c6;
$link-azure-1: #17b2cb;
$link-azure-2: #2c8ba8;
$link-azure-3: #165f7c;

// interface elements and tables
$ui-lighter-grey: #f7f7f7;
$ui-light-grey: #f3f3f3;
$ui-dark-grey: #c6c6c6;
$ui-light-grey-1: #d2d2d2;
$ui-light-grey-2: #d3d3d3;



$ui-light-blue-1: #ebeff1;
$ui-light-blue-2: #849ca9;
$ui-light-blue-3: #6b8492;
$ui-light-blue-3-traparent: rgba(#6c8593, 0.4);

$ui-dark-blue-0: #5B7584;
$ui-dark-blue-1: #525f6b;
$ui-dark-blue-2: #2e3c4a;
$ui-dark-blue-3: #252f3a;
$terms-conditions-bg:	rgba(#9da3aa, 0.66);

$ui-yellow: #ffc017;

// text
$text-white: #fff;

$text-grey-1: #dfdfdf;
$text-grey-2: #939393;
$text-grey-3: #6c6c6c;

$text-blue-1: #525f6b;
$text-blue-2: #2e3c4a;
$text-blue-3: #001515;

$black: #001b1b;
$white: #fff;

// forms
$primary-button-bg: #f36b12;
$primary-button-bg-hover: #c75b15;
$primary-button-bg-active: #a74100;

// alerts
$alert-red: #ef4518;
$alert-yellow: #ffc017;
$alert-azure: #17b2cb;
$alert-green: #a2ba3c;


//box Shadow
$box-shadow: rgba(#b0b0b0, 0.5);
