.delete-column, .details-column, .confirmed-column, .paid-column, .certificate-column {

	span {

		display: inline-block;
		width: 3rem;
		height: 3rem;
		background-image: url("../images/icons/icon-undone.svg");
		font-size: 0;

	}

}

.table-actions {
	float: right;
}

.confirmed-column.confirmed span {
	background-image: url("../images/icons/icon-done.svg");
}

.paid-column.paid span {
	background-image: url("../images/icons/icon-done.svg");
}

.certificate-column.uploaded span {
	background-image: url("../images/icons/icon-done.svg");
}

.details-column {

	span {
		background-image: url("../images/icons/icon-lens.svg");
		cursor: pointer;
	}

}

.delete-column {

	span {
		background-image: url("../images/icons/delete.svg");
		cursor: pointer;
	}

}
