.select-route-splash {

	opacity: 0;
	text-align: center;

	.select-route-message {

		padding-top: 22rem;

		background-image: url("../images/route.svg");
		background-position: top center;
		background-size: 20rem auto;

		font-size: 2.3rem;
		font-weight: 600;
		text-align: center;
		color: $ui-dark-grey;

	}

}

.race-results {
	overflow: hidden;
}

.table-filters {

	font-size: 1.8rem;
	display: flex;
	justify-content: space-between;

	margin-bottom: 20px;

	.sld-select {
		margin-left: 20px;
		margin-top: 10px;

		&:first-child {
			margin-left: 0;
		}

	}

}

.table-footer {

	display: flex;
	flex-direction: row;
	color: $ui-dark-grey;

	font-size: 1.6rem;

}

.dataTables_info {

	text-align: left;
	align-self: center;

	width: 20%;

}

.dataTables_paginate {

	font-weight: 700;
	text-align: center;
	align-self: center;

	width: 60%;

	.paginate_button {
		font-weight: 500;
	}

}

.dataTables_length {

	display: flex;
	justify-content: flex-end;
	align-items: center;

	width: 20%;

	.select-label {
		margin-left: 5px;
	}

	.sld-select--no-border li {
		padding-right: 15px;
	}

}

.dataTables_length,
.dataTables_filter,
.dataTables_paginate,
.dataTables_info {
	margin: 20px 0;
}

.paginate_button {

	padding: 5px;
	color: $ui-light-blue-2;

	cursor: pointer;
	outline: none;

	&:hover {
		color: $ui-dark-blue-2;
	}

	&.current {
		color: $ui-dark-blue-3;
	}

	&.next,
	&.previous {
		font-weight: 700;
	}

	&.next {

		margin-left: 20px;

		padding-right: 15px;
		background-image: url("../images/icons/icon-arrow-right.svg");
		background-position: right center;
		background-repeat: no-repeat;

	}

	&.previous {

		margin-right: 20px;

		padding-left: 15px;
		background-image: url("../images/icons/icon-arrow-left.svg");
		background-position: left center;
		background-repeat: no-repeat;

	}

}

@media( max-width: 800px ) {

	.race-results-header header {

		display: block;

		margin-top: 3rem;
		margin-bottom: 1rem;

		.share-race-results {

			margin-top: 1rem;
			text-align: left;

		}

		.share-btn {

			width: 3rem;
			margin-right: 1rem;

			object {
				margin: 0;
			}

		}

		.fb-share,
		.twtt-share {
			width: 3rem;
			height: 4rem;
			background-position: left;
		}

	}

	.table-footer {
		display: flex;
		flex-direction: row;
		color: $ui-dark-grey;
	}

	.dataTables_info {
		display: none;
	}

	.dataTables_paginate {
		text-align: left;
	}

	.dataTables_length {
		width: 40%;
	}

	.results-filter {
		display: none;
	}

	.results-search {

		width: 100%;
		box-sizing: border-box;

		input {
			width: 100%;
			box-sizing: border-box;
		}
	}

}

@media(max-width: 500px) {

	.dataTables_length {
		display: none;
	}

	.dataTables_paginate {
		text-align: center;
		width: 100%;
	}

}
