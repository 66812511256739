@import 'forms/buttons';
@import 'forms/text-field';
@import 'forms/select';
@import 'forms/custom-select';

form {

	h4 {
		width: 100%;
	}

}

form {

	width: 100%;
	box-sizing: border-box;

	text-align: left;

	input[type="text"],
	input[type="email"],
	input[type="password"],
	input[type="number"],
	textarea
	{
		display: inline-block;
		width: 100%;
	}

}

.big-form {
	max-width: 80rem;
	margin:0 auto;
}

.double-column-form-row {

	width: 100%;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.form-field {
		width: 45%;
	}

}

.multiple-column-form-row {

	width: 100%;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.form-field {
		width: auto;
	}

	&--2-row {
		.form-field {
			width: 47%;
		}
	}

	&--3-row {
		.form-field {
			width: 30%;
		}
	}
}

form label {

	display: inline-block;

	color: $ui-light-blue-3;

	font-weight: 700;

	padding: 0.5rem 0;

	&.secondary-label {
		font-weight: 500;
		color: $text-grey-2;
	}

}

.box-input-icon {

	input[type=radio],
	input[type=checkbox]{
		/* Hide original inputs */
		visibility: hidden;
		position: absolute;
	}
	input[type=radio] + label,
	input[type=checkbox] + label {
		width: 17px;
		height: 17px;
		border-radius: 50%;
		border: 1px solid $ui-light-blue-3-traparent;
	}
	input[type=radio] + label:before,
	input[type=checkbox] + label:before{
		height:9px;
		width:9px;
		content: " ";
		display:inline-block;
		vertical-align: baseline;
	}
	input[type=radio]:checked + label:before,
	input[type=checkbox]:checked + label:before{
		background:$ui-light-blue-3;
		border:3px solid white;
	}

	input[type=radio] + label:before{
		border-radius:50%;
	}
	input[type=checkbox] + label:before{
		border-radius:2px;
	}

}
.form-field {

	margin-bottom: 1rem;
	text-align: left;

	&.full-width {
		width: 100%;
	}

	&.flex {

		width: auto;

		input[type="text"],
		input[type="email"],
		input[type="password"],
		select {
			width: auto;
		}

	}

	.error-message {

		display: block;
		padding: 0.2rem;

		background-color: $alert-red;

    font-size: 1.1rem;
		text-align: right;
		color: $white;
		font-weight: 700;

	}
	input[type="radio"]:checked {
		color: $ui-light-blue-3;
	}
	input[type="text"],
	input[type="email"],
	input[type="password"],
	select {
		width: 100%;
	}

	.error {
		border: 1px solid $alert-red;
	}

}

.form-footer {
	margin-top: 4rem;
	margin-bottom: 4rem;
	width: 100%;
	text-align: right;
}

.form-separator {
	width: 100%;
	border-bottom: 1px solid $ui-light-grey;
	margin: 5rem 0;
}
@media (max-width: 768px) {
	.double-column-form-row {
		.form-field {
			width: 100%;
		}
	}
	.multiple-column-form-row {
		&--3-row {
			.form-field {
				width: 100%;
			}
		}

	}
}


.uploaded-image-preview {

	float: right;

	img {
		display: block;
	}

	button {
		display: block;
		margin: 10px 0;
	}

}
