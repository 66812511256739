.breadcrumbs {

	color: $text-grey-1;

	a {
		color: $text-grey-2;
	}

	a.selected {
		color: $ui-light-blue-3;
	}

}
