.order-details-specs {

	display: flex;
	justify-content: space-between;

	margin: 40px 0;

	b {
		display: block;
	}

	span {
		color: $text-grey-2;
	}

}
